import React from "react"
import Button from "@components/Button/Button"
import ButtonGroup from "@components/Button/ButtonGroup"

import ImageMeta from "../../components/ImageMeta"

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
const converter = new showdown.Converter()

function ImageTextAOMS(props) {
  return (
    <section className="home-section text-picture custom-aoms">
      <div className="columns reverse-columns is-vcentered">
        <div className="column is-4"></div>
        <div className="column is-6 picture-column ">
          <div className="columns">
            <div className="column">
              <ImageMeta
                cloudName="nuvolum"
                publicId="AOMS/DEV/homepage-aahc-desktop"
                width="auto"
                responsive
                data-aos-duration="1200"
                data-aos="fade-left"
                className="full-width"
                data-aos-anchor-placement="center-bottom"
              />
            </div>
          </div>
        </div>
        <div className="column is-1 "></div>
        <div
          data-aos="fade-up"
          data-aos-duration="200"
          id="first-column"
          className="column text-column mobile-pad">
          {props.language === "es" ? (
            <p className="large">
              En Amarillo Oral & Maxillofacial Surgery & Dental Implants, nos
              preocupamos profundamente por su seguridad y comodidad, por lo que
              siempre nos esforzamos por brindar atención de la más alta calidad
              en nuestro centro quirúrgico de vanguardia. Como institución
              acreditada por la AAAHC (Asociación de Acreditación para la
              Atención Ambulatoria de la Salud), podemos asegurarle que nuestro
              centro es el más avanzado y actualizado para el tratamiento
              quirúrgico.
            </p>
          ) : (
            <p className="large">
              At Amarillo Oral & Maxillofacial Surgery & Dental Implants, we
              care deeply about your safety and comfort, which is why we always
              strive to provide the highest quality of care in our
              state-of-the-art surgical facility. As a facility that has
              received accreditation from the Accreditation Association for
              Ambulatory Health Care (AAAHC), we can assure you that our
              facility is the most advanced and up to date for surgical
              treatment.
            </p>
          )}

          <div className="columns is-vcentered">
            <div className="column">
              <div className="aoms-logo-wrapper">
                <ImageMeta
                  cloudName="nuvolum"
                  className="aoms-logo"
                  publicId="AOMS/DEV/homepage-aaahc-logo"
                  width="auto"
                  responsive
                />
              </div>
            </div>
            <div className="column">
              <ButtonGroup isCenteredMobile alignLeft>
                <Button
                  external
                  buttonText={
                    props.language === "es" ? "Más Información" : "Learn More"
                  }
                  contained
                  href="https://www.aaahc.org/"
                />
              </ButtonGroup>
            </div>
          </div>
        </div>

        <div className="column is-4"></div>
      </div>
    </section>
  )
}

export default ImageTextAOMS
