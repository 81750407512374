import PropTypes from "prop-types"
import React from "react"
import ImageMeta from "../ImageMeta"
import Button from "@components/Button/Button"

import TimedElement from "./TimedElement"

function ChristmasPark() {
  return (
    <TimedElement endDate="20 Dec 2020 00:00:00 CST">
      <div className="christmas-park">
        <ImageMeta
          cloudName="nuvolum"
          publicId="AOMS/DEV/homepage-christmas-in-the-park-logo"
          width="auto"
          responsive
          // data-aos-duration="1200"
          // data-aos="fade-left"
          // className="full-width"
          // data-aos-anchor-placement="center-bottom"
        />
        <div className="stripe">
          <span className="stripe-text">
            <strong>MAKE A DIFFERENCE </strong>THIS SEASON
          </span>
        </div>
        <span className="text">
          Let us come together as a community and give to those in need. <br />
          Click below to volunteer or donate.
        </span>

        <div className="columns has-text-centered">
          <div className="column">
            <Button
              buttonText="Sign Up"
              external
              regular
              href="https://secureform.seamlessdocs.com/f/homelessawarenessmonth"
            />
          </div>
        </div>
      </div>
    </TimedElement>
  )
}

export default ChristmasPark
