import React from "react"
import Button from "@components/Button/Button"
import ButtonGroup from "@components/Button/ButtonGroup"

import ImageMeta from "../../components/ImageMeta"
import MarkdownViewer from "@components/MarkdownViewer"

function FourHourSmileBanner(props) {
  return (
    <section
      className="home-section color-back four-hour-smile-banner"
      style={{
        paddingBottom: "0",
        paddingTop: "0",
        marginTop: "0",
        marginBottom: "40px"
      }}>
      <div className="columns is-vcentered is-desktop reverse-columns-touch">
        <div className="column is-2"></div>
        <div className="column">
          <div className="text">
            <h2>Complete Your Smile in Just 4&nbsp;Hours</h2>
            <p>
              The 4-Hour Smile procedure is a complete treatment that allows
              patients to smile again. Dental implants support an entire upper
              or lower dental prosthesis for results that are healthy,
              functional, and&nbsp;natural-looking.
            </p>
          </div>
          <Button
            href="/procedure/4-hour-smile-amarillo-tx/"
            buttonText="4-Hour Smile"
            contained
            standalone
          />
        </div>
        <div className="column is-1"></div>
        <div className="column is-11">
          <ImageMeta
            cloudName="nuvolum"
            publicId="AOMS/DEV/homepage-4-hours-smile"
            width="auto"
            responsive
          />
        </div>
      </div>
    </section>
  )
}

export default FourHourSmileBanner
