import React from "react"
import Button from "@components/Button/Button"
import ButtonGroup from "@components/Button/ButtonGroup"

import ImageMeta from "../../components/ImageMeta"

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
const converter = new showdown.Converter()

function ImageText(props) {
  if (props.topDown) {
    return (
      <section
        className="home-section text-picture home-section-1 top-down"
        style={{ paddingBottom: "100px", paddingTop: "100px" }}>
        <div className="columns">
          <div className="column is-2"></div>
          <div data-aos="fade-up" data-aos-duration="200" id="first-column">
            <ImageMeta
              cloudName="nuvolum"
              publicId={props.post.homeSectionOne.imageId}
              width="auto"
              responsive
              data-aos-duration="1200"
              data-aos="fade-left"
              className="full-width"
              data-aos-anchor-placement="center-bottom"
            />
          </div>
          <div className="column is-2"></div>
        </div>
        <div className="columns">
          <div className="column is-3"></div>
          <div
            className="column"
            data-aos="fade-up"
            data-aos-duration="200"
            id="first-column">
            <div
              dangerouslySetInnerHTML={createHtml(
                converter.makeHtml(props.post.homeSectionOne.heading)
              )}
              className="has-text-centered"></div>
            <div
              dangerouslySetInnerHTML={createHtml(
                converter.makeHtml(props.post.homeSectionOne.text)
              )}></div>
            <ButtonGroup isCentered noReverse={props.noReverse}>
              <Button
                buttonText={props.post.homeSectionOne.buttonOne.buttonText}
                minimal
                href={props.post.homeSectionOne.buttonOne.href}
              />
              <Button
                buttonText={props.post.homeSectionOne.buttonTwo.buttonText}
                href={props.post.homeSectionOne.buttonTwo.href}
              />
            </ButtonGroup>
          </div>
          <div className="column is-3"></div>
        </div>
      </section>
    )
  }
  return (
    <section
      className="home-section text-picture color-back home-section-1"
      style={{ paddingBottom: "100px", paddingTop: "100px" }}>
      <div className="columns is-vcentered reverse-columns">
        <div className="column is-2"></div>
        <div className="column is-10 picture-column is-hidden-touch-fix">
          <div className="columns">
            <div className="column">
              <ImageMeta
                cloudName="nuvolum"
                publicId={props.post.homeSectionOne.imageId}
                width="auto"
                responsive
                data-aos-duration="1200"
                data-aos="fade-left"
                className="full-width"
                data-aos-anchor-placement="center-bottom"
              />
            </div>
          </div>
        </div>
        <div className="column is-1 is-hidden-touch-fix"></div>
        <div
          data-aos="fade-up"
          data-aos-duration="200"
          id="first-column"
          className="column text-column mobile-pad">
          <div className="columns">
            <div className="column">
              <div
                dangerouslySetInnerHTML={createHtml(
                  converter.makeHtml(props.post.homeSectionOne.heading)
                )}></div>
              <div
                dangerouslySetInnerHTML={createHtml(
                  converter.makeHtml(props.post.homeSectionOne.text)
                )}></div>
              <ButtonGroup
                tight
                isCenteredMobile
                alignLeft
                noReverse={props.noReverse}>
                <Button
                  buttonText={props.post.homeSectionOne.buttonOne.buttonText}
                  contained
                  href={props.post.homeSectionOne.buttonOne.href}
                />
                <Button
                  buttonText={props.post.homeSectionOne.buttonTwo.buttonText}
                  href={props.post.homeSectionOne.buttonTwo.href}
                />
              </ButtonGroup>
            </div>
          </div>
        </div>

        <div className="column is-3"></div>
      </div>
    </section>
  )
}

export default ImageText
