import React, { useState } from "react"
import { Link } from "gatsby"

import "./ProgramToast.scss"

//Props
// href = page url
// endDate = program application end date, format = month day year (September 23 2021)
// program = program name ex. "smile again"

const ProgramToast = ({ href, endDate, program, custom }) => {
  program = program.toLowerCase().trim()

  const [close, setClose] = useState(false)

  const today = new Date()
  const end = new Date(endDate)

  const dateWords = endDate.split(" ")
  const displayDate = `${dateWords[0]} ${dateWords[1]}`

  if (close || !program || today > end) return <></>

  const programs = {
    "smile again": (
      <p>
        Apply to the <Link to={href}>Smile Again</Link> program by {displayDate}{" "}
        for a chance to receive a new smile.
      </p>
    ),
    "second chance": (
      <p>
        Apply to the <Link to={href}>Second Chance</Link> program by{" "}
        {displayDate} for a chance to receive a new smile.
      </p>
    ),
    "smiles for soldiers": (
      <p>
        We're providing two active duty soldiers or military veterans with a new
        smile. <Link to={href}>Apply by {displayDate}!</Link>
      </p>
    ),
    "wisdom for wisdom": (
      <p>
        We're providing FREE wisdom teeth removal surgery to two local students.{" "}
        <Link to={href}>Apply before {displayDate}!</Link>
      </p>
    ),
    custom: (
      <>
        <p style={{ fontWeight: "bold", fontSize: "24px", margin: 0 }}>
          Academic Advantage
        </p>
        <p style={{ marginTop: "10px" }}>
          Monday March 6th, 2023
          <br />
          9:00 am - 2:00 pm <br />
          Amarillo Civic Center Auditorium
        </p>
      </>
    )
  }

  return (
    <div className="program-toast">
      <button
        aria-label="close banner"
        className="close-toast"
        onClick={() => setClose(true)}>
        ✖
      </button>
      {programs[program]}
    </div>
  )
}

export default ProgramToast
